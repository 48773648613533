import React, { useState } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollToPlugin } from 'gsap/all';
import { useIntl } from 'gatsby-plugin-intl';
import { useMedia } from 'use-media';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ClientOnly from '../../components/clientOnly';
import Cursor from '../../components/cursor';

import { Section, Div, H1, H2 } from '../../components/styles';
import Footer from '../../components/footer';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MotionPathPlugin);

const IndexPage = () => {
  const [isActiveModal, setActiveModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isTablet = useMedia({ minWidth: '768px' });
  const Intl = useIntl();

  return (
    <Layout>
      <SEO
        title={Intl.formatMessage({ id: 'title' })}
        desc={Intl.formatMessage({ id: 'description' })}
        keywords={Intl.formatMessage({ id: 'keywords' })}
        noIndex
      />
      <main>
        <ClientOnly>
          {isTablet && !isLoading && (
            <Cursor color="0, 255, 36" outerAlpha={1} innerSize={6} outerSize={16} outerScale={2} />
          )}
        </ClientOnly>

        <Section id="stay-tuned" bg="var(--section-bg-color)" data-section={1}>
          <H1
            fontSize={['3.5rem', '3.5rem', '4rem', '5rem']} // was: 4
            lineHeight={['4rem', '4rem', '6rem']} // was: 4.1
            className="intro-heading"
            maxWidth="900px"
            display="grid"
            left="29%"
            position="relative"
          >
            dpraward – Produktdemo Justt
          </H1>
          <Div
            className="trigger-white"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="700px"
            minHeight={['100vh', '100vh', '10vh', '10vh', '300px']}
            mx="auto"
            px={['0', '0', '0', '8rem']}
          >
            <H2 fontSize={['2.4rem', '2.4rem', '3.2rem']} lineHeight={['2.9rem', '2.9rem', '4rem']}>
              App Version 1.0
            </H2>
            <Div>
              <video
                controls
                preload="metadata"
                muted={false}
                autoPlay={false}
                playsInline
                src="https://justt-media-dev.s3.eu-central-1.amazonaws.com/App+MVP.mp4"
              />
            </Div>
          </Div>
        </Section>
        {/* //----------------------------------------------------------------------------------------------------
        //----------------------------------Second Video------------------------------------
        //---------------------------------------------------------------------------------------------------- */}

        <Section id="stay-tuned" bg="var(--section-bg-color)" data-section={1}>
          <Div
            className="trigger-white"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1440px"
            // minHeight={['100vh', '100vh', '100vh', '100vh', '650px']}
            mx="auto"
            px={['0', '0', '0', '8rem']}
          >
            <H2 fontSize={['2.4rem', '2.4rem', '3.2rem']} lineHeight={['2.9rem', '2.9rem', '4rem']}>
              Content Creator Dashboard Version 1.0
            </H2>
            <Div>
              <video
                controls
                preload="metadata"
                muted={false}
                autoPlay={false}
                playsInline
                src="https://justt-media-dev.s3.eu-central-1.amazonaws.com/Y-Combinator+CMS+Demo.mov"
              />
            </Div>
          </Div>
        </Section>
        <Section minHeight="120px" data-section={4}>
          <Footer />
        </Section>
      </main>
    </Layout>
  );
};

export default IndexPage;
